import React from 'react';

import { COMPANY_NAME, COMPANY_PHONE_ALT } from '../shared/globals';
import { LayoutTypes, TextSize, ButtonVariant, NavLinkStyleTypes, ContentFloat, ContentVerticalFloat, InfoBlockBgLinePosition, ViewHeightVariant, SpacerSize, HeaderTextTypes, TextTypes } from '../types';
import { heroSlideMain } from '../data/page-home-content';
import { PageContentSectionBlock, PageContentBlock, PageContentAlignment } from './styles';
import { getMainMenuItems } from '../utils/nav-menu';
import NavigationLinkWithContext from '../components/NavigationLinkWithContext';

import GeneralLayout from '../layouts/GeneralLayout';
import BlockSection from '../components/BlockSection';
import ContainerWithBackground from '../components/ContainerWithBackground';
import ContainerGrid from '../components/ContainerGrid';
import Spacer from '../components/Spacer';
import BodyText from '../components/BodyText';
import SectionTitle from '../components/SectionTitle';
import NumeratedListItem from '../components/NumereatedListItem';
import CardBlock from '../components/CardBlock';
import BgShapeBlock from '../components/BgShapeBlock';
import NavigationLink from '../components/NavigationLink';
import Image from '../components/Image';
import TestimonialListComponent from '../components/Testimonials';
import BoderShapeBlock from '../components/BoderShapeBlock';
import { FormContextProvider } from '../context/FormContext';
import WithFormContext from '../components/WithFormContext';
import BuyingOfferMultiStepForm from '../components/BuyingOfferMultiStepForm';
import HeaderText from '../components/HeaderText';

const HomePage = () => {

  const baseUrl = process.env.REACT_APP_BUYING_HOUSE_DOMAIN_BASE_URL;
  const baseParentUrl = process.env.REACT_APP_SOPHINEX_DOMAIN_BASE_URL;
  const navOptions = {
    ...getMainMenuItems(LayoutTypes.home),
    baseUrl,
    baseParentUrl,
    isBgDark: false,
    isSideBgDark: false
  };

  return (
    <GeneralLayout { ...navOptions }>

      <ContainerWithBackground 
        bgImage={ '31179995_10.jpg' }
        bgColor={ '#e2e2e2' } 
        bgOverlayColor={ '#ffffff'} 
        bgOverlayTransparency={ 0.6 }
        heightVariant={ ViewHeightVariant.viewport }
      >
        <BlockSection {...heroSlideMain} textSize={ TextSize.large } sectionHeight={ '100%' } highlightContent={ true } className='h-100' style={{ paddingTop: '120px' }} />
      </ContainerWithBackground>

      <Spacer id='reason-to-sell'></Spacer>

      <ContainerGrid>
        <PageContentBlock>
          <div className='row pt-2'>
            <div className='col-xs-12 col-md-12 col-lg-7'>
              <SectionTitle
                topText={ 'WE ARE HERE TO HELP YOU' }
                headerText={ 'Reasons To Sell Houses To Us' }
                textSize={ TextSize.large }
                headerFloat={ ContentFloat.left }
              >
                <BodyText type='div' alignment={ 'justify' }>
                  <p>You can sell your house to { COMPANY_NAME } quickly if you find yourself in any of the following situations:</p>
                </BodyText>
              </SectionTitle>
              <PageContentSectionBlock vAlign={ ContentFloat.center } className='pe-0'>
                <div className='row'>
                  <div className='col-xs-12 col-md-12 col-lg-6'>
                    <NumeratedListItem 
                      itemNum={ '01' } 
                      color={ '#74b587' } 
                      title={ 'Inherited Property' } 
                      text={ 'Inherited property but do not need it.' }
                      variant={ 'compact' } 
                      className={ 'pb-3' } />
                    <NumeratedListItem 
                      itemNum={ '02' } 
                      color={ '#74b587' } 
                      title={ 'Relocation' } 
                      text={ 'Moving cities or states for a job.' }
                      variant={ 'compact' }
                      className={ 'pb-3' } />
                    <NumeratedListItem 
                      itemNum={ '03' } 
                      color={ '#74b587' } 
                      title={ 'Home Upgrade' } 
                      text={ 'Upgrading home to a bigger one.' }
                      variant={ 'compact' }
                      className={ 'pb-3' } />
                    <NumeratedListItem 
                      itemNum={ '04' } 
                      color={ '#74b587' } 
                      title={ 'Downsize Homes' } 
                      text={ 'Downsizing your home to save some money.' }
                      variant={ 'compact' }
                      className={ 'pb-3' } />
                  </div>
                  <div className='col-xs-12 col-md-12 col-lg-6'>
                    <NumeratedListItem 
                      itemNum={ '05' } 
                      color={ '#74b587' } 
                      title={ 'Avoiding Foreclosure' } 
                      text={ 'Avoiding foreclosure to save your credit.' }
                      variant={ 'compact' }
                      className={ 'pb-3' } />
                    <NumeratedListItem 
                      itemNum={ '06' } 
                      color={ '#74b587' } 
                      title={ 'Avoiding Bankruptcies' } 
                      text={ 'Avoiding bankruptcy to save your credit.' }
                      variant={ 'compact' }
                      className={ 'pb-3' } />
                    <NumeratedListItem 
                      itemNum={ '07' } 
                      color={ '#74b587' } 
                      title={ 'Family Divorce' } 
                      text={ 'Selling your house if you are facing divorcing or in the middle of it.' }
                      variant={ 'compact' }
                      className={ 'pb-3' } />
                    <NumeratedListItem 
                      itemNum={ '08' } 
                      color={ '#74b587' } 
                      title={ 'Health or Medical Issues' } 
                      text={ 'No longer can afford mortgage monthly payments.' }
                      variant={ 'compact' }
                      className={ 'pb-3' } />
                  </div>
                </div>
              </PageContentSectionBlock>
            </div>
            <div className='col-xs-12 col-md-12 col-lg-5'>
              <BgShapeBlock shapePos={ InfoBlockBgLinePosition.side } float={ ContentFloat.left } fullHeight={ true } noGap={ true } baseZIndex={ 0 }>
                <PageContentAlignment align={ ContentFloat.center } vAlign={ ContentVerticalFloat.center } fullHeight={ true } noGap={ true }>
                  <Image 
                    img={ 'for-sale-amico-building.svg' } 
                    style={{zIndex: '1'}} 
                    imgSourceColor={ 'on-light' }
                    imgSourceHref={ 'https://storyset.com/building' }
                    imgSourceTitle={ 'Building illustrations by Storyset' }
                    imgSourceIntro={ 'illustrations by storyset' }
                    imgSourceAlignment={ 'bottom-center' }
                    imgSourceVerticalOffset={ -30 }
                    maxWidth={ 100 }
                  />
                </PageContentAlignment>
              </BgShapeBlock>
            </div>
          </div>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer id='better-way-to-sell' />

      <ContainerGrid>
        <PageContentBlock>
          <div className='row g-4'>
            <div className='col-xs-12 col-md-12 col-lg-6'>
              <BgShapeBlock shapePos={ InfoBlockBgLinePosition.side } float={ ContentFloat.right } fullHeight={ true } baseZIndex={ 0 }>
                <PageContentAlignment align={ ContentFloat.center } vAlign={ ContentVerticalFloat.center } fullHeight={ true } noGap={ true }>
                  <Image 
                    img={ 'face-to-face-bro-people.svg' } 
                    style={{zIndex: '3'}} 
                    imgSourceColor={ 'on-light' }
                    imgSourceHref={ 'https://storyset.com/work' }
                    imgSourceTitle={ 'Work illustrations by Storyset' }
                    imgSourceIntro={ 'illustrations by storyset' }
                    imgSourceAlignment={ 'bottom-center' }
                  />
                </PageContentAlignment>
              </BgShapeBlock>
            </div>
            <div className='col-xs-12 col-md-12 col-lg-6'>
              <PageContentAlignment align={ ContentFloat.left } vAlign={ ContentVerticalFloat.top } fullHeight={ true } noGap={ true }>
                <SectionTitle
                  topText={ 'WE MAKE IT SIMPLE' }
                  headerText={ 'A Better Way To Sell Houses With Us' }
                  textSize={ TextSize.large }
                  headerFloat={ ContentFloat.right }
                >
                  <BodyText type='div' alignment={ 'justify' }>
                    <p>Selling your house through a real estate agent (traditional way) can be a lengthy process, taking several months to find a qualified buyer, with no guarantees that it will sell. Additionally, you will be required to spend a significant amount of money on preparing your home for sale, along with paying the agent's commissions and closing fees.</p>
                    <p>We do not use traditional way of selling properties. We have own proven strategy that saves sellers time and money. Our services are 100% free. Selling houses with us offers many benefits which set us apart.</p>
                  </BodyText>
                  <Spacer size='xs'></Spacer>
                  <PageContentAlignment align={ ContentFloat.right } vAlign={ 'center' } noGap={ true }>
                    <NavigationLinkWithContext 
                      title={ 'Learn About Benefits' } 
                      path={ '/' } 
                      hash={ 'benefits-you-get' } 
                      isExternalLink={ false } 
                      style={ NavLinkStyleTypes.button }
                      variant={ ButtonVariant.primary }
                      dark={ false } 
                      alt={ 'Learn About Benefits' } />
                  </PageContentAlignment>
                  
                </SectionTitle>
              </PageContentAlignment>
            </div>
          </div>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer className='d-none' />

      <ContainerGrid isFullWidth={ true } className='d-none'>
        <ContainerWithBackground
          bgImage={ '31179995_10.jpg' }
          bgColor={ '#e2e2e2' } 
          bgOverlayColor={ '#000000'} 
          bgOverlayTransparency={ 0.3 }
          takeFullViewport ={ false }
        >
          <ContainerGrid>
            <div className='row'>
              <div className='col-xs-12 col-md-6 col-lg-7'>
                <PageContentBlock className='py-5 px-1'>
                  <SectionTitle
                    headerText={ 'Are You Facing Foreclosure?' }
                    textSize={ TextSize.middle }
                    headerFloat={ ContentFloat.right }
                  />
                </PageContentBlock>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-5 h-100'>
                <PageContentAlignment align={ ContentFloat.left } vAlign={ 'center' } noGap={ true } className='h-100'>
                  <NavigationLink 
                    title={ 'Learn More How To Avoid It' } 
                    to={ 'https://noforeclosure.sophinex.com' }
                    style={ NavLinkStyleTypes.button } 
                    isExternalLink={ true } 
                    isScrollNav={ false } 
                    variant={ ButtonVariant.primary }
                    className={ 'inverted' }
                    dark={ false }
                    alt='Apply For Surplus Funds' />
                </PageContentAlignment>
                <Spacer size='md'></Spacer>
              </div>
            </div>
          </ContainerGrid>
        </ContainerWithBackground>
      </ContainerGrid>

      <Spacer id='benefits-you-get'></Spacer>

      <ContainerGrid>
        <PageContentBlock>
          <BgShapeBlock shapePos={ InfoBlockBgLinePosition.side } float={ ContentFloat.left } fullHeight={ true } baseZIndex={ 0 }>
            <div className='row pt-4'>
              <div className='col-xs-12 col-md-6 col-lg-8'>
                <SectionTitle
                  topText={ 'SELL HOUSES WITH US' }
                  headerText={ 'Benefits You Get' }
                  textSize={ TextSize.large }
                  headerFloat={ ContentFloat.left }
                >
                  <BodyText type='div' size={ TextSize.middle } alignment={ 'justify' }>
                    <span>We leverage our real estate and financial expertise to help <NavigationLinkWithContext title={ 'homeowners' } path={ '/#testimonials' } style={ NavLinkStyleTypes.normal } alt={ 'Go to testimonials' } /> sell their houses quickly and efficiently, without any expenses, through our simple <NavigationLinkWithContext title={ 'workflow' } path={ '/#workflow' } style={ NavLinkStyleTypes.normal } alt={ 'Our workflow' } />.</span>
                  </BodyText>
                </SectionTitle>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-4 p-0'>
                <CardBlock title={ 'Fast Offer' } subTitle={ '#1' }  bgColor={ '#fdf1f0' } borderColor={ '#fdf1f0' } minHeight={ '250px' }>
                    <span>We will make you a no-obligation offer in less than 24 hours once we get all information about your property.</span>
                </CardBlock>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-4 p-0'>
                <CardBlock title={ 'No Real Estate Agents' } subTitle={ '#2' }  bgColor={ '#e2f2fb' } borderColor={ '#e2f2fb' } minHeight={ '250px' }>
                    <span>We do not utilize the services of real estate agents, which means that there are no commissions involved.</span>
                </CardBlock>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-4 p-0'>
                <CardBlock title={ 'Buy "As Is"' } subTitle={ '#3' } bgColor={ '#e2fbfc' } borderColor={ '#e2fbfc' } minHeight={ '250px' }>
                    <span>We buy properties in their current condition, so no need to spend on preparing or upgrading for sale.</span>
                </CardBlock>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-4 p-0'>
                <CardBlock title={ 'Cash Deal' } subTitle={ '#4' } bgColor={ '#eaf5ea' } borderColor={ '#eaf5ea' } minHeight={ '250px' }>
                    <span>It is our policy to use cash options as the primary payment method for buying any property.</span>
                </CardBlock>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-4 p-0'>
                <CardBlock title={ 'No Expencies' } subTitle={ '#5' } bgColor={ '#f9fae8' } borderColor={ '#f9fae8' } minHeight={ '250px' }>
                    <span>We provide all our services completely free of charge, with no hidden fees or charges.</span>
                </CardBlock>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-4 p-0'>
                <CardBlock title={ 'Fast Closing' } subTitle={ '#6' } bgColor={ '#fcf3e5' } borderColor={ '#fcf3e5' } minHeight={ '250px' }>
                    <span>With our fast offer and trusted title company, we can complete the selling process in just 1-3 weeks (depends on case).</span>
                </CardBlock>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-4 p-0'>
                <CardBlock title={ 'No Closing Fees' } subTitle={ '#7' } bgColor={ '#fdeee7' } borderColor={ '#fdeee7' } minHeight={ '250px' }>
                    <span>You don’t have to spend anything once you close.</span>
                </CardBlock>
              </div>
            </div>
            <Spacer size={ SpacerSize.sm }></Spacer>
            <PageContentAlignment align={ ContentFloat.center } noGap={ true }>
              <NavigationLinkWithContext 
                title={ 'Get Your Free Offer' } 
                path={ '/' } 
                hash={ 'get-offer' } 
                isExternalLink={ false } 
                style={ NavLinkStyleTypes.button }
                variant={ ButtonVariant.success }
                dark={ false } 
                alt={ 'Get Your Free Offer' } />
            </PageContentAlignment>
          </BgShapeBlock>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer id='benefits-you-get-' className='d-none' />

      <ContainerGrid className='d-none'>
        <PageContentBlock>
          <BgShapeBlock shapePos={ InfoBlockBgLinePosition.side } float={ ContentFloat.left } fullHeight={ true } baseZIndex={ 0 }>
            <div className='row pt-4'>
              <div className='col-xs-12 col-md-6 col-lg-4'>
                <SectionTitle
                  topText={ 'SELLING HOUSE TO US' }
                  headerText={ 'Benefits You Get' }
                  textSize={ TextSize.small }
                  headerFloat={ ContentFloat.left }
                >
                  <BodyText type='div' size={ TextSize.middle } alignment={ 'justify' }>
                    <span>We leverage our real estate and financial expertise to help <NavigationLinkWithContext title={ 'homeowners' } path={ '/#testimonials' } style={ NavLinkStyleTypes.normal } alt={ 'Go to testimonials' } /> sell their houses quickly and efficiently, without any expenses, through our simple <NavigationLinkWithContext title={ 'workflow' } path={ '/#workflow' } style={ NavLinkStyleTypes.normal } alt={ 'Our workflow' } />.</span>
                  </BodyText>
                </SectionTitle>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-4 p-0'>
                <CardBlock title={ 'No Agents' } subTitle={ '#1' }  bgColor={ '#fdf1f0' } borderColor={ '#fdf1f0' } minHeight={ '250px' }>
                    <span>We do not utilize the services of real estate agents, which means that there are no commissions involved.</span>
                </CardBlock>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-4 p-0'>
                <CardBlock title={ 'Buy "As Is"' } subTitle={ '#2' } bgColor={ '#e2f2fb' } borderColor={ '#e2f2fb' } minHeight={ '250px' }>
                    <span>We buy properties in their current condition, so no need to spend on preparing or upgrading for sale.</span>
                </CardBlock>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-4 p-0'>
                <CardBlock title={ 'Cash Deal' } subTitle={ '#3' } bgColor={ '#f9fae8' } borderColor={ '#f9fae8' } minHeight={ '250px' }>
                    <span>It is our policy to use cash options as the primary payment method for buying any property.</span>
                </CardBlock>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-4 p-0'>
                <CardBlock title={ 'No Expencies' } subTitle={ '#4' } bgColor={ '#eaf5ea' } borderColor={ '#eaf5ea' } minHeight={ '250px' }>
                    <span>We provide all our services completely free of charge, with no hidden fees or charges.</span>
                </CardBlock>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-4 p-0'>
                <CardBlock title={ 'Fast Closing' } subTitle={ '#5' } bgColor={ '#fcf3e5' } borderColor={ '#fcf3e5' } minHeight={ '250px' }>
                    <span>With our fast offer and trusted title company, we can complete the selling process in just 1-3 weeks (depends on case).</span>
                </CardBlock>
              </div>
            </div>
            <Spacer size={ SpacerSize.sm }></Spacer>
            <PageContentAlignment align={ ContentFloat.center } noGap={ true }>
              <NavigationLinkWithContext 
                title={ 'Get Your Free Offer' } 
                path={ '/' } 
                hash={ 'get-offer' } 
                isExternalLink={ false } 
                style={ NavLinkStyleTypes.button }
                variant={ ButtonVariant.success }
                dark={ false } 
                alt={ 'Get Your Free Offer' } />
            </PageContentAlignment>
          </BgShapeBlock>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer id='workflow' />

      <ContainerGrid isFullWidth={ true }>
        <ContainerWithBackground
            bgColor={ '#e2e2e2' } 
            bgOverlayColor={ '#000000'} 
            bgOverlayTransparency={ 0.3 }
            takeFullViewport ={ false }
        >
            <ContainerGrid>
                <PageContentBlock className='p-5'>
                    <SectionTitle
                        topText={ 'How It Works' }
                        headerText={ 'A Workflow To Sell Your House' }
                        textSize={ TextSize.large }
                        headerFloat={ ContentFloat.center }
                        textFloat={ ContentFloat.center }
                        isVertical={ true }
                        align={ ContentFloat.center }
                    >
                        <BodyText type='div' alignment={ ContentFloat.center }>
                            <span>Looking for a hassle-free way to sell your property? Here is the most convenient way to get it done.</span>
                        </BodyText>

                        <Spacer size='sm'></Spacer>

                        <div className='row'>
                            <div className='col-xs-12 col-md-6 col-lg-6 p-0'>
                                <div className='row'>
                                    <div className='col-xs-12 col-md-12 col-lg-12 p-4'>
                                        <NumeratedListItem 
                                          itemNum={ '01' } 
                                          color={ '#6382AA' } 
                                          title={ 'Request a free offer or give us a call' } 
                                          text={ 'We gather all necessary information on this step to create an accurate offer for you.' }/>
                                    </div>
                                    <div className='col-xs-12 col-md-12 col-lg-12 p-4'>
                                        <NumeratedListItem 
                                          itemNum={ '02' } 
                                          color={ '#E37B7B' } 
                                          title={ 'Get the offer' } 
                                          text={ 'Within 24 hours, we will analyze all the information you provided in step 1 and make you a free no-obligation offer.' } />
                                    </div>
                                </div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-6 p-0'>
                                <div className='row'>
                                    <div className='col-xs-12 col-md-12 col-lg-12 p-4'>
                                        <NumeratedListItem 
                                          itemNum={ '03' } 
                                          color={ '#93BC93' } 
                                          title={ 'Signing documents' } 
                                          text={ 'After having our offer accepted, we will sign all the necessary documents to initiate the closing process.' } />
                                    </div>
                                    <div className='col-xs-12 col-md-12 col-lg-12 p-4'>
                                        <NumeratedListItem 
                                          itemNum={ '04' } 
                                          color={ '#949596' } 
                                          title={ 'Closing' } 
                                          text={ 'We meet at the title company\'s office or use on-line technologies to sign documents for closing the deal and transferring funds.' } />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Spacer size='sm'></Spacer>

                        <HeaderText text={ 'Our services are 100% free of charge.' } type='h5' align={ ContentFloat.center }></HeaderText>

                        <Spacer size='md'></Spacer>

                        <PageContentAlignment align={ ContentFloat.center } noGap={ true }>
                            <NavigationLinkWithContext 
                                title={ 'Get Your Free Offer' } 
                                path={ '/' } 
                                hash={ 'get-offer' } 
                                isExternalLink={ false } 
                                style={ NavLinkStyleTypes.button }
                                variant={ ButtonVariant.success }
                                dark={ false } 
                                alt={ 'Get Your Free Offer' } />
                        </PageContentAlignment>
                    </SectionTitle>
                </PageContentBlock>
            </ContainerGrid>
        </ContainerWithBackground>
      </ContainerGrid>

      <Spacer />

      <ContainerGrid>
        <PageContentBlock>
            <BgShapeBlock shapePos={ InfoBlockBgLinePosition.half } float={ ContentFloat.left } baseZIndex={ 0 }>
                <SectionTitle
                    topText={ '' }
                    headerText={ 'Have Questions?' }
                    textSize={ TextSize.middle }
                >
                    <BodyText type='div' alignment='justify'>
                        <span>Try to find any answers in our list of frequently asked questions first OR call us at { COMPANY_PHONE_ALT } OR use "Contact Us" form. We look forward to hearing from you.</span>
                    </BodyText>
                    <Spacer size='sm'></Spacer>
                    <PageContentAlignment align={ ContentFloat.right } noGap={ true }>
                        <NavigationLinkWithContext 
                          title={ 'Contact Us' } 
                          path={ '/contact-us' } 
                          isExternalLink={ false } 
                          style={ NavLinkStyleTypes.button }
                          variant={ ButtonVariant.secondary }
                          className='inverted m-2'
                          dark={ false } 
                          alt={ 'Contact Us' } />
                        <NavigationLinkWithContext 
                          title={ 'Go to FAQs' } 
                          path={ '/faq' } 
                          isExternalLink={ false } 
                          style={ NavLinkStyleTypes.button }
                          variant={ ButtonVariant.primary }
                          className='m-2'
                          dark={ false } 
                          alt={ 'Go to FAQs' } />
                    </PageContentAlignment>
                </SectionTitle>
            </BgShapeBlock>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer id='testimonials' className='d-none-' />

      <ContainerGrid isFullWidth={ true } className='d-none-'>
        <ContainerWithBackground
              bgColor={ '#e2e2e2' } 
              bgOverlayColor={ '#000000'} 
              bgOverlayTransparency={ 0.3 }
              takeFullViewport ={ false }
          >
            <ContainerGrid>
              <PageContentBlock>
                <Spacer></Spacer>
                <TestimonialListComponent headerText={ 'What Home Owners Say' }></TestimonialListComponent>
                <Spacer></Spacer>
                <PageContentAlignment align={ ContentFloat.center } noGap={ true }>
                  <NavigationLinkWithContext 
                    title={ 'Get Your Free Offer' } 
                    path={ '/' } 
                    hash={ 'get-offer' } 
                    isExternalLink={ false } 
                    style={ NavLinkStyleTypes.button }
                    variant={ ButtonVariant.success }
                    dark={ false } 
                    alt={ 'Get Your Free Offer' } />
                </PageContentAlignment>
                <Spacer></Spacer>
              </PageContentBlock>
            </ContainerGrid>
          </ContainerWithBackground>
      </ContainerGrid>

      <Spacer id='get-offer' />

      <ContainerGrid>
        <PageContentBlock>
          <BoderShapeBlock color={ '#88bc95' } opacity={ 0.15 }>
            <BgShapeBlock shapePos={ InfoBlockBgLinePosition.none } float={ ContentFloat.center } fullHeight={ false } baseZIndex={ 0 }>
              <div className='row g-4'>
                <div className='col-xs-12 col-md-12 col-lg-6'>
                    <PageContentAlignment align={ ContentFloat.center } vAlign={ ContentVerticalFloat.center } fullHeight={ false } noGap={ true }>
                      <Image 
                        img={ 'mail-sent-pana-work.svg' } 
                        style={{zIndex: '3'}} 
                        imgSourceColor={ 'on-light' }
                        imgSourceHref={ 'https://storyset.com/work' }
                        imgSourceTitle={ 'Work illustrations by Storyset' }
                        imgSourceIntro={ 'illustrations by storyset' }
                        imgSourceAlignment={ 'bottom-center' }
                      />
                    </PageContentAlignment>
                </div>
                <div className='col-xs-12 col-md-12 col-lg-6'>
                  <PageContentAlignment align={ ContentFloat.left } vAlign={ ContentVerticalFloat.top } fullHeight={ true } noGap={ true }>
                    <SectionTitle
                      headerText={ 'Request Your Free Offer Today' }
                      textSize={ TextSize.large }
                      headerFloat={ ContentFloat.left }
                    >
                      <BodyText type='div' alignment={ 'justify' }>
                        <p>Tell us about your property to receive a free no-obligation offer in less than 24 hours. Please complete all required fields (marked with *) to help us make the right offer for you.</p>
                        <p>We do not share your personal information.</p>
                      </BodyText>
                      <Spacer size='sm'></Spacer>
                      <ContainerWithBackground 
                        bgColor={ '#e2e2e2' } 
                        bgOverlayColor={ '#ffffff'} 
                        bgOverlayTransparency={ 0.3 }
                        heightVariant={ ViewHeightVariant.auto }
                        className='p-4 card-shadow'
                        style={{ borderRadius: '16px' }}
                      >
                        <FormContextProvider>
                          <WithFormContext render={(propsWithContext) => <BuyingOfferMultiStepForm { ...propsWithContext }></BuyingOfferMultiStepForm>} />
                        </FormContextProvider>
                      </ContainerWithBackground>
                    </SectionTitle>
                  </PageContentAlignment>
                </div>
              </div>
              </BgShapeBlock>
          </BoderShapeBlock>
        </PageContentBlock>
      </ContainerGrid>

      <ContainerGrid isFullWidth={ true } className='d-none-'>
        <ContainerWithBackground
          bgImage={ '31179995_10.jpg' }
          bgColor={ '#e2e2e2' } 
          bgOverlayColor={ '#000000'} 
          bgOverlayTransparency={ 0.3 }
          takeFullViewport ={ false }
        >
          <ContainerGrid>
            <div className='row'>
              <div className='col-xs-12 col-md-6 col-lg-7'>
                <PageContentBlock className='py-5 px-1'>
                  <SectionTitle
                    headerText={ `Lost Your House Due To Foreclosure?` }
                    textSize={ TextSize.middle }
                    headerFloat={ ContentFloat.right }
                  />
                </PageContentBlock>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-5 h-100'>
                <PageContentAlignment align={ ContentFloat.left } vAlign={ 'center' } noGap={ true } className='h-100'>
                  <NavigationLinkWithContext 
                    title={ 'Learn About Assets Recovery Program' } 
                    path={ 'https://surplus-funds.sophinex.com' } 
                    isExternalLink={ true } 
                    style={ NavLinkStyleTypes.button }
                    className='inverted' 
                    variant={ ButtonVariant.primary }
                    dark={ false } 
                    alt={ 'Learn About Assets Recovery Program' } />
                </PageContentAlignment>
                <Spacer size='md'></Spacer>
              </div>
            </div>
          </ContainerGrid>
        </ContainerWithBackground>
      </ContainerGrid>

      <Spacer />

      <ContainerGrid>
        <PageContentBlock>
          <BgShapeBlock shapePos={ InfoBlockBgLinePosition.half } float={ ContentFloat.right } baseZIndex={ 0 }>
            <SectionTitle
              headerText={ 'Disclaimer' }
              textSize={ TextSize.middle }
              headerFloat={ ContentFloat.right }
            >
              <BodyText text={ 'This website is not intended to provide nor does it provide legal advice.' } type={ TextTypes.p } alignment={ ContentFloat.right } />
            </SectionTitle>
          </BgShapeBlock>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer />

    </GeneralLayout>
  );
}

export default HomePage;
