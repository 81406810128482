import React from 'react';

import { SectionWrapper } from './styles';
import { TextSize } from '../types';
//import MainLayout from '../layouts/MainLayout';
import GeneralLayout from '../layouts/GeneralLayout';
import Section from '../components/Section';
import SectionTitle from '../components/SectionTitle';
import { NavLinkStyleTypes } from '../types';
import { LayoutTypes } from '../types';
import NavigationLinkWithContext from '../components/NavigationLinkWithContext';
import { COMPANY_EMAIL, COMPANY_FULL_NAME, COMPANY_PHONE } from '../shared/globals';
import { getMainMenuItems } from '../utils/nav-menu';
import BodyText from '../components/BodyText';

const CookiePolicyPage = () => {

  const navOptions = {
    ...getMainMenuItems(LayoutTypes.main),    
    isBgDark: false,
    isSideBgDark: false
  };

  return (
    <GeneralLayout {...navOptions}>
      <SectionWrapper>
        <Section id={'cookie-policy'} lightBg={true}>
          <SectionTitle
            topText={ 'January 2024' }
            headerText={ 'Cookies Policy' }
            textSize={ TextSize.middle }
            style={{ paddingBottom: '40px' }}
          >
            <BodyText type='div'>
              <p><b>{ COMPANY_FULL_NAME }</b> ("Company," "We," or "Us,") has developed this Cookies Policy to help you understand what common tools, such as cookies and other technologies, we use to make your use of our online environments easier and more meaningful. These tools allow us to provide better service, customize our online environments based on consumer preferences, compile statistics, provide you with more relevant advertisements, analyze trends, and otherwise administer and improve our online environments.</p>
              
              <p><b>WHAT IS A COOKIE?</b></p>
              
              <p>A cookie is a small file that is installed on your computer or mobile device when you visit almost any website. These cookies form part of the technology necessary to make the website work properly and are not inherently harmful. Any information we collect about you through your use of cookies is subject to our Privacy Policy.</p>
              
              <p><b>TYPES OF COOKIES</b></p>
              
              <p>Different types of cookies perform different tasks. The table below provides general information about cookie types.</p>
              
              <p><b>Technical cookies</b></p>
              
              <p>These cookies allow the user to browse a website, platform or application and to use different options or services therein. For example, controlling traffic and communication of data, identifying the session, accessing restricted areas, remembering the items of an order, carrying out a purchase process, applying for registration or participation in an event, using security features while browsing, storing content to share videos or audio, or sharing content through social media.</p>
              
              <p><b>Customization cookies</b></p>
              
              <p>These cookies allow the user to access the service with some general features which are predefined according to some criteria on the user's device. For example, language, type of browser through which the service is accessed, regional configuration where the service is accessed from, etc.</p>
              
              <p><b>Analytical cookies</b></p>
              
              <p>These cookies allow the entity responsible for them to track and analyze the behavior of the users of the websites to which they are linked. The information collected through this type of cookie is used to measure the activity of websites, applications and platforms and to define browsing profiles of the users of said websites, applications and platforms, with the aim of making improvements based on the analysis of the data of the users' use of the services.</p>
              
              <p><b>Advertising cookies</b></p>
              
              <p>These cookies allow for the management, as effectively as possible, of the advertising space which, where appropriate, the publisher has included in a website, application, or platform from which the requested service is provided, based on criteria such as the published content or the frequency at which the advertisements are shown.</p>
              
              <p><b>Behavioral advertising cookies</b></p>
              
              <p>These cookies allow for the management, as effectively as possible, of the advertising space which, where appropriate, the publisher has included in a website, application, or platform from which the requested service is provided. These cookies store information of the users' behavior obtained through the continued monitoring of their browsing habits. This allows for developing a specific profile to show advertising based on it.</p>

              <p><b>HOW CAN YOU DISABLE COOKIES?</b></p>
              
              <p>You can set your browser settings to reject all cookies. However, it is possible that some features or services of the website will not work properly without cookies. Most browsers allow you to completely disable cookies, so that you may at any time choose what cookies you would like to work on the website by adjusting your browser settings. You can find more information in your browser settings.</p>
              
              <p><b>TECHNICAL INFORMATION WE COLLECT AUTOMATICALLY</b></p>
              
              <p>We may collect certain technical information about your visits to our online environments without you actively submitting such information. This information can make your use of our online environments easier and more meaningful by allowing us to provide better service, customize our online environments based on consumer preferences, compile statistics, provide you with more relevant advertisements, analyze trends and otherwise administer and improve our online environments. As discussed in the "HOW OUR PARTNERS USE COOKIES FOR ADVERTISING" section below, such information may also be collected by our advertising vendors and other partners.</p>
              <p>Some of the types of technical information collected automatically when visiting our online environments include:</p>
              <ul>
                <li><b>Log Data.</b> When you use our online environments, our servers automatically record information ("log data"), including information that your browser or mobile app sends whenever you visit or use our online environments. This log data may include your Internet Protocol address, the addresses of the web pages you visited, browser types and settings, the date and time of your request, how you used our online environments and cookie data;</li><br/>
                <li><b>Cookie Data.</b> Depending on how you are accessing our online environments, we may use "cookies" (as described above, a small text file sent by your device each time you visit our websites, unique to your browser) or similar technologies to record log data. When we use cookies, we may use "session" cookies (that last until you close your browser) or "persistent" cookies (that last until you or your browser delete them). For example, we may use cookies to remember your personal preferences, such as sections of our online environments that you visit frequently, your user ID, or other settings so you don't have to set them up every time you visit our online environments. Please see the "HOW OUR PARTNERS USE COOKIES FOR ADVERTISING" section below for more information; and</li><br/>
                <li><b>Device Information.</b> In addition to log data, we may also collect information about the device you are using to access our online environments, including what type of device it is, what operating system you are using, as well as device settings, unique device identifiers and crash data. Whether we collect some or all of this information often depends on what type of device you are using and its settings. For example, different types of information are available depending on whether you are using a Mac or a PC, or an iPhone or an Android phone. To learn more about what information your device makes available to us, please also check the policies of your device manufacturer or software provider.</li>
              </ul>

              <p><b>HOW OUR PARTNERS USE COOKIES FOR ADVERTISING</b></p>
              
              <p>We use common tools, such as cookies and similar technologies (discussed in the "TECHNICAL INFORMATION WE COLLECT AUTOMATICALLY" section above), to collect information about consumers' use of our online environments for purposes of enhancing their online experience and delivering more meaningful advertisements. Like many companies, we may also utilize third-party advertising vendors and other partners to collect this information.</p>
              <p>One way in which we deliver more meaningful advertisements is through a common form of online advertising known as "retargeting" or "remarketing." Retargeting works by serving ads on one site based on consumer's online activities on a different site. To do this our advertising vendors may use a device ID, cookie, or similar technology placed by us or the advertising vendor when you visit our online environments or third-party websites or apps. The placing of these cookies or other technologies on your device may enable you to be identified across multiple websites and you should consult the privacy policies of our advertising vendors to understand how they collect and use your data.</p>
              <p>Your ability to control the use of tracking tools, such as cookies, and opt-out of retargeting activities is described in the "CHOICES YOU HAVE ABOUT YOUR INFORMATION" section below.</p>
              <p>Also, we may extract an email address provided by you in a partially completed online form for purposes of sending you advertisements relating to our services.</p>
              
              <p><b>CHOICES YOU HAVE ABOUT YOUR INFORMATION</b></p>

              <p>You may always limit the amount and type of information that we collect from you by choosing not to enter or provide any information requested from you on our online and offline environments (for example, online and offline contact forms, support requests, and requests made by our customer care center agents). However, some of our services can only be provided to you if you provide us with requested information. Some of the services offered through our online and offline environments may ask whether you wish to opt out or opt into our contact lists for offers, promotions, and additional services that may be of interest to you.</p>
              <p>You can opt out of marketing emails we may send to you by following the "unsubscribe" instructions provided in such emails. If you opt out of our marketing emails, we may still send you transactional and relationship emails, such as emails about your requests and account.</p>
              <p>You may also be provided with preference questions or preference boxes allowing you to indicate that you do not want our online environments to use tracking technologies, such as cookies, to "remember" the information collected on return visits, such as mailing addresses; however, the tracking technologies used to collect unidentifiable information generally do not provide you with the ability to opt out of those tracking technologies. In addition, you may be able to control the use of tracking tools and limit retargeting activities as follows:</p>
              <ul>
                <li><b>Controlling Tracking Tools.</b> Most web browsers have an option for turning off the cookie feature, which will prevent your browser from accepting new cookies, as well as (depending on the sophistication of your browser software) allowing you to decide on acceptance of each new cookie in a variety of ways. For more information about cookies and how to refuse them, visit here and here. To control flash cookies, which we may use on certain online environments from time to time, you can go here (note, this separate link is provided because flash cookies cannot be controlled through your browser settings).</li><br/>
                <li><b>Opting-Out of Retargeting Activities.</b> The Self-Regulatory Program for Online Behavioral Advertising program provides consumers with the ability to opt-out of having their online behavior recorded and used for advertising purposes. To opt out of having your online behavior collected for advertising purposes and receiving behavioral advertisements from our advertising partners, including retargeting advertisements, click <a href="https://www.aboutads.info/choices/" target="_blank">here</a> or <a href="http://www.networkadvertising.org/choices/#completed" target="_blank">here</a>.</li>
              </ul>
              <p>Choices you make are both browser and device specific. Also, certain versions of the iOS operating system permit you to "Limit Ad Tracking" in the system settings, and certain versions of the Android operating system allow you to "Opt-out of Interest-Based Ads" in the system settings.</p>

              <p><b>DO-NOT-TRACK SIGNALS AND SIMILAR MECHANISMS</b></p>

              <p>Some web browsers may transmit "do-not-track" signals to websites with which the browser communicates. As of the issue date of this policy, an industry standard has not yet been established on how to respond to these signals. Therefore, we do not currently respond to these signals, but we may reassess our response approach once a standard is established.</p>

              <p><b>CONTACT US</b></p>

              <p>If you have any questions or comments about this Cookies Policy or the ways in which we use information subject to this policy, please do not hesitate to contact us by:</p>
              <ul>
                <li>Clicking on the <NavigationLinkWithContext title={ '"Contact Us"' } path={ '/contact-us' } style={ NavLinkStyleTypes.normal } alt={ 'Contact Us' } /> link on the online environment you are visiting;</li>
                <li>E-mailing us at <b>{ COMPANY_EMAIL }</b>; or</li>
                <li>Calling us at <b>{ COMPANY_PHONE }</b> (toll-free).</li>
              </ul>
            </BodyText>
          </SectionTitle>
        </Section>
      </SectionWrapper>
    </GeneralLayout>
  );
}

export default CookiePolicyPage;
