import React from 'react';

import { SectionWrapper } from './styles';
import { TextSize } from '../types';
import GeneralLayout from '../layouts/GeneralLayout';
import Section from '../components/Section';
import SectionTitle from '../components/SectionTitle';
import { NavLinkStyleTypes } from '../types';
import { LayoutTypes } from '../types';
import NavigationLinkWithContext from '../components/NavigationLinkWithContext';
import { COMPANY_EMAIL, COMPANY_FULL_NAME, COMPANY_PHONE } from '../shared/globals';
import { getMainMenuItems } from '../utils/nav-menu';
import BodyText from '../components/BodyText';

const PrivacyPolicyPage = () => {

  const navOptions = {
    ...getMainMenuItems(LayoutTypes.main),    
    isBgDark: false,
    isSideBgDark: false
  };


  return (
    <GeneralLayout {...navOptions}>
      <SectionWrapper>
        <Section id={'provate-policy'} lightBg={true}>
          <SectionTitle
            topText={ 'January 2024' }
            headerText={ 'Privacy Statement' }
            textSize={ TextSize.middle }
          >
            <BodyText type='div'>
              <p><b>{ COMPANY_FULL_NAME }</b> (“Company,” “We,” or “Us,”) has developed this Privacy Policy to help you understand what information we collect about you, how we use it, who we share it with, how we protect it, and your rights as relates to its use. This Privacy Policy applies to information collected through:</p>
              <ul>
                <li>Your use of our services;</li>
                <li>Transacting via sophinex.com and any other websites, mobile apps, or other online services that display or link to this Privacy Policy (collectively, "online environments");</li>
                <li>Offline services, including our Customer Care Center (collectively, "offline environments"); and</li>
                <li>Information shared with us by other sources for our own internal purposes.</li>
              </ul>
              <p>As indicated below we have provided additional information about how we collect and share your Personal Information and your rights with respect to your Personal Information. You have the right to request a copy of the information we store about you, inquire as to how we use that information, who we share it with, or to request its deletion when it is no longer subject to regulatory or compliance retention requirements. This Privacy Policy applies to both our online and offline activities.</p>
              <p>If you have any questions or comments about this Privacy Policy or the ways in which Company uses the information it collects about you, please do not hesitate to contact us using the information provided in the <NavigationLinkWithContext title={ '"Contact Us"' } path={ '/privacy-policy?#contact-us' } style={ NavLinkStyleTypes.normal } alt={ 'Contact Us' } /> section of this Privacy Policy.</p>
              
              <p class="w-100 d-none">UPDATED VERSION IS COMMING SOON...</p>

              <div className='d-none-'>

              <p><b>WHEN THIS PRIVACY POLICY DOES NOT APPLY</b></p>
              
              <p>This Privacy Policy does not apply to:</p>
              <ul>
                <li>Websites, mobile apps, or other services that have their own privacy policies;</li>
                <li>Websites, mobile apps, or other services that do not display or link to this Privacy Policy; or</li>
                <li>Information collected for employment-related or business-to-business related purposes.</li>
              </ul>
              <p>If you have any questions as to whether this Privacy Policy applies to you, please do not hesitate to contact us using the information provided in the <NavigationLinkWithContext title={ '"Contact Us"' } path={ '/privacy-policy?#contact-us' } style={ NavLinkStyleTypes.normal } alt={ 'Contact Us' } /> section of this Privacy Policy.</p>
              
              <p><b>INFORMATION WE COLLECT</b></p>
              
              <p>Personal Information means any information that identifies, relates to, describes, or is capable of being associated with you or your household, whether directly or indirectly. All companies need to collect and share consumers’ Personal Information for everyday business purposes, marketing, and maintenance of the safety, security, and integrity of their websites and other assets, among other reasons.</p>
              <p>We collect the information you provide when you:</p>
              <ul>
                <li>Order our services or create an account with us;</li>
                <li>Request support or submit an inquiry to our Customer Care Center;</li>
                <li>Sign up to receive information about our services;</li>
                <li>Respond to surveys or participate in contests or promotions.</li>
              </ul>
              <p>This information may include:</p>
              <ul>
                <li><b>Identifiers</b>, such as your real name, postal address, e-mail address, phone numbers, or username;</li>
                <li><b>Financial information</b>, such as your bank account number, credit or debit card number, or other financial information;</li>
                <li><b>Transactional information</b>, such as services obtained, or considered, or other consuming histories, equipment maintenance data to provide on-demand services, contract details, written signatures, or recordings of your calls to our Customer Care Center.</li>
              </ul>
              
              <p><b>TECHNICAL INFORMATION COLLECTED AUTOMATICALLY</b></p>
              
              <p>We collect the information provided when you transact through our online environments without you actively submitting such information, such as:</p>
              <ul>
                <li><b>Identifiers</b>, such as your internet protocol (IP) address and geolocation data;</li>
                <li><b>Network activity data</b>, such as browsing history, search history, and information regarding your individual interactions with an internet website, application, or advertisement.</li>
              </ul>        
              <p>This information can make your use of our online environments easier and more meaningful by allowing us to provide better service, customize our online environments based on your consumer preferences, compile statistics, provide you with more relevant advertisements, analyze trends, and otherwise administer and improve our online environments. For more information, refer to our <NavigationLinkWithContext title={ '"Cookies Policy"' } path={ '/cookie-policy' } style={ NavLinkStyleTypes.normal } alt={ 'Cookies Policy' } />.</p>
              <p>We may also combine the information we collect through one or more of our online environments with information we collect through other online environments. We may also combine information collected through our online environments with information collected through our offline environments, as well as with information provided by our partners. We use this consolidated information to help us improve our online environments, and services; to communicate information to you; to enhance our marketing and research activities; and to engage in any other uses described in the <NavigationLinkWithContext title={ '"HOW WE USE THE INFORMATION WE COLLECT"' } path={ '/privacy-policy?#how-we-use-collected-info' } style={ NavLinkStyleTypes.normal } alt={ 'How we use the information we collect' } /> section below.</p>
              
              <p><b>INFORMATION COLLECTED FROM OUR BUSINESS PARTNERS</b></p>
              
              <p>Our advertising vendors may share information with us regarding our ads on websites or apps where such ads run. This enables us to measure and improve those ads by including information such as whether clicks on ads led to purchases, or a list of criteria to use in targeting ads. We may obtain information from our other partners, such as co-sponsors who we may partner with to run special contests or other co-branded promotions. We may obtain information from other commercially available sources such as data aggregators, marketing firms, and public databases to provide us with additional information about our existing consumers (this is known as “data appending”). This information may include name, demographic information, contact information, interests, and publicly observed data such as from social media and shopping behavior. We may receive information about consumers when we acquire all or part of other companies. We assess this data to determine how it was collected and what consent was given for its use.</p>
              
              <p id="how-we-use-collected-info"><b>HOW WE USE THE INFORMATION WE COLLECT</b></p>
              
              <p>We use the information we collect to:</p>
              <ul>
                <li>Provide services, process payments or money transfers;</li>
                <li>Respond to requests for information and services; and</li>
                <li>Improve our services.</li>
              </ul>
              <p>We also use the information we collect in anonymized, aggregated form for customer preference analyses, and to:</p>
              <ul>
                <li>Develop new services;</li>
                <li>Determine how to best protect the data of our company and our consumer.</li>
              </ul>
              <p><b>Identifiers</b> – We may use the information you provide to us to:</p>
              <ul>
                <li>Send you advertisements, notices, and other information regarding our services, including notifying you about special promotions;</li>
                <li>Respond to your inquiries or support requests;</li>
                <li>Invite you to participate in surveys regarding our services;</li>
                <li>Determine your eligibility for, and to register you in, our contests and other promotions, and contact you regarding such contests or other promotions;</li>
                <li>Furnish and maintain our online environments; and</li>
                <li>Secure our networks and online environments.</li>
              </ul>
              <p><b>Financial information</b> – If you make a purchase through our online or offline environments and authorize us to use your credit or debit card information, we will use this information to charge you as authorized. In these situations, we may also save your contact and payment information (using commonly accepted security measures) so that you can use them the next time you want to order something from us.</p>
              <p><b>Transactional information</b> – If you register or open an account with us, the information we collect may be used to maintain your account, provide you with access to some features of our online environments, or offer you the benefits and privileges that typically come along with account registration. This may include purchasing services, use of exclusive or personalized content or activities (such as message boards), and participation in special events and promotions.</p>
              <p><b>Technical information</b> – We may also use the information we collect to offer you customized content by:</p>
              <ul>
                <li>Recognizing new or past visitors to our online environments;</li>
                <li>Remembering your personal preferences;</li>
                <li>Showing you more relevant and meaningful advertisements;</li>
                <li>Better understanding the interests of our consumers and visitors to our online environments; and</li>
                <li>Conducting research and analyzing the use of our online environments, services, and advertisements.</li>
              </ul>
              <p>Some of our applications and mobile-optimized online environments may have location-based features. To deliver these features, we may access and use location data provided by your mobile device. Location information will be collected solely to deliver the requested feature and will not be further retained or stored by us.</p>
              
              <p><b>SHARING YOUR PERSONAL INFORMATION</b></p>
              
              <p><b>WE DO NOT SELL YOUR PERSONAL INFORMATION</b> to third parties. We may share some or all of your personal information as required or permitted by law and to the following persons or entities:</p>
              <ul>
                <li><b>Vendors and Suppliers</b> – We have relationships with different vendors and suppliers who help us design and maintain our online environments, systems, and computer security, respond to consumer inquiries, mail invoices, analyze our data, and engage in any other information uses described in this Privacy Policy.</li><br/>
                <li><b>Legal Requests and Preventing Harm</b> – We will disclose information as reasonably necessary to comply with a law, regulation, or legal request; to protect the safety, rights, or property of the public, any person, or Company; or to detect, prevent, or otherwise address fraud, security, or technical issues.</li><br/>
                <li><b>Change of Ownership or Control</b> – We may engage in an acquisition, bankruptcy, dissolution, reorganization, or similar transaction or proceeding that involves the transfer of the information described in this Privacy Policy.</li>
              </ul>
              
              <p><b>OTHER IMPORTANT INFORMATION ABOUT OUR RELATIONSHIP WITH THIRD PARTIES</b></p>
              
              <p><b>Links to Other Sites</b> – We may feature links to websites that we believe you might find useful and informative. Please be aware, however, that we do not endorse or recommend these sites' content or services, and we are not responsible for the privacy practices of these other sites. We encourage you to be aware of and read the privacy policy of each site you visit. Remember, this Privacy Policy applies solely to information collected or used by Company.</p>
              <p><b>Social Media Plugins</b> – Our online environments may use social media plugins (for example, the Facebook "Like" button or "Share to Twitter" button) to enable you to easily share information with others. When you visit our online environments, the operator of the social plugin can place a cookie on your device, enabling that operator to recognize individuals who have previously visited our online environments. Please view our Cookie Policy for more information about removing cookies. If you are logged into the social media website (for example, Facebook, Twitter, Google+) while browsing on our online environments, the social plugins allow that social media website to receive information that you have visited our online environments. The social plugins also allow the social media website to share information about your activities on our online environments with other users of their social media website. For example, Facebook Social Plugins allow Facebook to show your "Likes" and comments on our online environments to your Facebook friends. Facebook Social Plugins also allow you to see your friends' Facebook activity on our online environments. We do not control any of the content from the social media plugins. For more information about social plugins from other social media websites, you should refer to those sites' privacy and data sharing policies.</p>
              
              <p><b>RIGHTS AND CHOICES YOU HAVE ABOUT YOUR PERSONAL INFORMATION</b></p>
              
              <p>You have the following rights with respect to your Personal Information:</p>
              <ul>
                <li><b>Right to Notice.</b> Before or at the time we collect Personal Information from you, you have the right to receive notice of the Personal Information to be collected and the purposes for which we use it. This Notice is intended to satisfy this requirement.</li><br/>
                <li>You also have the right to request that we disclose to you the categories of personal information we have collected about you in the preceding 12 months, along with the categories of sources from which the personal information was collected, the purpose for collecting the personal information, and the categories of third parties with whom we shared the personal information.</li><br/>
                <li><b>Right of Access.</b> You have the right to request that we disclose or provide you with access to the specific pieces of Personal Information we have collected about you in the preceding 12 months.</li><br/>
                <li><b>Right to Deletion.</b> You have the right to request that we delete the Personal Information we collect from you. However, in certain situations we are not required to delete your Personal Information, such as when the information is necessary in order to complete the transaction for which the Personal Information was collected, to provide a good or service requested by you, to comply with a legal obligation, to engage in research, to secure our websites or other online services, or to otherwise use your Personal Information internally in a lawful manner that is compatible with the context in which you provided the information.</li><br/>
                <li><b>Right Not to Be Subject to Discrimination.</b> You have the right to be free from discrimination or retaliation for exercising any of your rights as described above. We may, however, charge a different price or rate, or provide a different level or quality of goods or services to you, if that difference is reasonably related to the value provided to Company by your Personal Information. Further, we may offer you financial incentives in return for your Personal Information.</li>
              </ul>
              <p>You can exercise any of your rights by using the information in the section below.</p>
              
              <p><b>COMPANY DOES NOT SELL YOUR PERSONAL INFORMATION.</b></p>
              
              <p><b>VERIFICATION OF YOUR IDENTITY</b></p>
              
              <p>We value the security and confidentiality of your Personal Information. Therefore, if you exercise your right to notice, right of access, or right to deletion, we must first verify your identity to make sure that you are the person about whom we have collected Personal Information. We verify every request carefully.</p>
              <p>You may also authorize someone else to submit these requests on your behalf. To do so, you may designate directly with us another person who may act on your behalf by emailing your request to the address shown below in the <NavigationLinkWithContext title={ '"Contact Us"' } path={ '/privacy-policy?#contact-us' } style={ NavLinkStyleTypes.normal } alt={ 'Contact Us' } /> section of this Privacy Policy and providing us with a notarized copy of a power of attorney, or by asking the authorized agent to provide us with a copy of your written permission and a scanned copy of their own government-issued ID.</p>
              
              <p><b>ALTERNATIVE FORMATS OF THIS PRIVACY POLICY</b></p>
              
              <p>If you are unable to review or access this Privacy Policy due to a disability, you may contact us at the Customer Care Center or via the other options defined in the <NavigationLinkWithContext title={ '"Contact Us"' } path={ '/privacy-policy?#contact-us' } style={ NavLinkStyleTypes.normal } alt={ 'Contact Us' } /> section below to request information in an alternative format.</p>
              
              <p><b>HOW WE SECURE YOUR INFORMATION</b></p>
              
              <p>We have instituted physical, technical, and procedural safeguards to store and maintain information we collect in a secure environment. You may also be required to use a password to access certain pages on our online environments where certain types of your information can be changed or deleted. It is therefore important for you to protect against unauthorized access to your password and to your device. You take full responsibility for maintaining the complexity and confidentiality of your password. While we have implemented safeguards, you should be aware that Internet security technology rapidly changes. We cannot guarantee that the safeguards we employ today can protect your information from the threats of tomorrow. You should also be aware that despite our efforts, factors beyond our control may result in disclosure of information. Accordingly, we are not able to guarantee that your information will be secure under all circumstances. We retain Personal Information collected through the online and offline services as long as necessary to provide the services and information you request or as permitted by applicable law.</p>
              
              <p><b>OUR POLICY ON CHILDREN’S INFORMATION</b></p>
              
              <p>We do not knowingly collect or use any information from children (we define "children" as minors younger than 16 (sixteen) years of age) on our online or offline environments. We do not knowingly allow children to order our services, communicate with us, or use any of our online environments. If you are a parent and become aware that your child has provided us with information, please contact us using one of the methods specified below, and we will work with you to address this issue.</p>

              <p><b>INTERNATIONAL TRANSFERS</b></p>
              
              <p>Our online and offline environments are designed for and targeted to audiences in the United States (“U.S.”) and are governed by and operated in accordance with the laws of the U.S. While users from countries other than the U.S. may access our online and offline environments, we make no representation that such environments are operated in accordance with the laws or regulations of, or governed by, other nations.</p>
              <p>Please be aware that by accessing our online and offline environments, or providing us with information, you understand and agree that:</p>
              <ul>
                <li>Information collected from you may be transferred to and stored on servers located outside your jurisdiction;</li>
                <li>To the extent you are a resident of a country other than the U.S., you consent to the transfer of such information to the U.S. for our use in accordance with this Privacy Policy; and</li>
                <li>This Privacy Policy, and the collection and use of information pursuant to this Privacy Policy, shall be governed by and construed in accordance with the laws of the state of Alabama without giving effect to any principles of conflicts of law, with all actions or proceedings arising in connection with this policy to be tried and litigated only in the state courts or federal courts located in Montgomery County, Alabama.</li>
              </ul>
              <p>Providing us with information or continuing to use our online or offline environments indicates that you are agreeing to the collection, use, disclosure, management, and storage of information collected from you as described in this Privacy Policy.</p>
              
              <p><b>LOOKBACK DISCLOSURE</b></p>
              
              <p>The categories of personal information and their collection, use, and disclosure in the preceding twelve (12) months are the same as those defined in this Privacy Policy.</p>
              
              </div>

              <p><b>CHANGES TO THIS PRIVACY POLICY</b></p>
              
              <p>We reserve the right to amend this Privacy Policy without prior notice to reflect technological advancements, legal and regulatory changes, and good business practices. If we change our privacy practices, a new Privacy Policy will reflect those changes and the effective date of the revised Privacy Policy will be set forth at the top of this Privacy Policy.</p>
              
              <p id="contact-us"><b>CONTACT US</b></p>
              
              <p>If you have any questions or comments about this Privacy Policy or the ways in which we use information subject to this policy, please do not hesitate to contact us by:</p>
              <ul>
                <li>Clicking on the <NavigationLinkWithContext title={ '"Contact Us"' } path={ '/contact-us' } style={ NavLinkStyleTypes.normal } alt={ 'Contact Us' } /> link on the online environment you are visiting;</li>
                <li>E-mailing us at <b>{ COMPANY_EMAIL}</b>;</li>
                <li>Calling us at <b>{ COMPANY_PHONE}</b> (toll-free).</li>
              </ul>
            </BodyText>
          </SectionTitle>
        </Section>
      </SectionWrapper>
      
    </GeneralLayout>
  );
}

export default PrivacyPolicyPage;
